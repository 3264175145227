<template>
  <div class="container-fluid inspection-global">
    <div class="page-title">
      <i class="fa-regular fa-car"></i>
      <h1>{{ $t("APPS.VEHICLES") }}</h1>
    </div>
    <div class="list-table">
      <vehicle-list-table
        @onAddVehicle="openVehicleCreateModal"
        @onViewVehicle="openVehicleViewModal"
        @onEditVehicle="openVehicleEditModal"
        @onDeleteVehicle="deleteVehicle"
        :key="renderKey * 100"
      />
    </div>

    <transition name="slide-right">
      <div
        v-if="isViewVehicleModalOpened"
        class="resizable-wrapper no-line"
        v-bind:class="[isViewVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'VEHICLE'"
          @onCloseModal="closeVehicleModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("VEHICLES.VIEW_VEHICLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openVehicle"
                :objectType="'vehicles'"
                :objectId="openVehicle.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <button class="expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="closeVehicleModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-vehicle-page
              v-if="openVehicle"
              :vehicleId="openVehicle.id"
              @onEditVehicle="openVehicleEditModal"
              @onViewVehicle="openVehicleViewModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isEditVehicleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'VEHICLE'"
          @onCloseModal="closeVehicleModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("VEHICLES.EDIT_VEHICLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="openVehicleViewModal(openVehicle)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-vehicle-page
              v-if="openVehicle"
              :vehicleId="openVehicle.id"
              @onViewVehicle="openVehicleViewModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isAddVehicleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'VEHICLE'"
          @onCloseModal="closeVehicleModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("VEHICLES.ADD_VEHICLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="closeVehicleModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-vehicle-page @onViewVehicle="openVehicleViewModal" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import VehicleListTable from "./partials/VehicleListTable.vue";
import EditVehiclePage from "./components/EditVehicleComponent.vue";
import AddVehiclePage from "./components/AddVehicleComponent.vue";
import ViewVehiclePage from "./components/ViewVehicleComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    VehicleListTable,
    NotificationSubscription,
    EditVehiclePage,
    AddVehiclePage,
    ViewVehiclePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const vehicleId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewVehicleModalOpened = false;
    let isEditVehicleModalOpened = false;
    let isAddVehicleModalOpened = false;
    let openVehicle = null;
    if (vehicleId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewVehicleModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditVehicleModalOpened = true;
      }
      openVehicle = { id: vehicleId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddVehicleModalOpened = true;
    }
    return {
      isViewVehicleModalOpened: isViewVehicleModalOpened,
      isEditVehicleModalOpened: isEditVehicleModalOpened,
      isAddVehicleModalOpened: isAddVehicleModalOpened,
      openVehicle: openVehicle,
      renderKey: 1,
    };
  },

  methods: {
    openVehicleCreateModal() {
      this.closeVehicleModal();
      this.isAddVehicleModalOpened = true;

      this.$router.replace({
        name: "List Vehicles",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },
    openVehicleViewModal(vehicle, reRender = false) {
      this.closeVehicleModal();
      this.openVehicle = vehicle;
      this.isViewVehicleModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Vehicles",
        query: { id: this.openVehicle.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openVehicleEditModal(vehicle) {
      this.closeVehicleModal();
      this.openVehicle = vehicle;
      this.isEditVehicleModalOpened = true;

      this.$router.replace({
        name: "List Vehicles",
        query: { id: this.openVehicle.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    closeVehicleModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddVehicleModalOpened = false;
      this.isViewVehicleModalOpened = false;
      this.isEditVehicleModalOpened = false;
      this.openVehicle = null;

      if (
        this.$route.name !== "List Vehicles" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Vehicles",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deleteVehicle(vehicle) {
      const confirmation = await swal.fire({
        title: this.$t("VEHICLES.DELETE_THIS_VEHICLE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("vehicles/destroy", vehicle.id);
          this.renderKey++;
          this.closeVehicleModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("VEHICLES.VEHICLE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
